import React from 'react';

const Library = ({ ...props }: React.ComponentProps<'svg'>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_165_7822)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 3H11V4H2V3ZM2 6H11V7H2V6ZM8 9H2V10H8V9ZM14 11.5L10.5 9V14L14 11.5Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_165_7822">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const DownToBottom = ({ ...props }: React.ComponentProps<'svg'>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 3.99999L8 8.99999L13 3.99999L12.3 3.29999L8 7.59999L3.7 3.29999L3 3.99999ZM2 12V11H14V12H2Z"
      fill="currentColor"
    />
  </svg>
);

const UpToTop = ({ ...props }: React.ComponentProps<'svg'>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 11.3L8 6.3L13 11.3L12.3 12L8 7.7L3.7 12L3 11.3ZM2 3.29999V4.29999H14V3.29999H2Z"
      fill="currentColor"
    />
  </svg>
);

const Moon = ({ ...props }: React.ComponentProps<'svg'>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 12 14" fill="none" {...props}>
    <path
      d="M5.49 0.5C5.46072 0.500046 5.43151 0.502656 5.4027 0.5078C3.81057 0.790594 2.3794 1.65251 1.38484 2.92753C0.390279 4.20254 -0.0973208 5.80048 0.0159592 7.41355C0.129239 9.02662 0.835386 10.5407 1.99839 11.6642C3.16139 12.7877 4.69898 13.4412 6.31499 13.4986C6.39704 13.5016 6.47909 13.4986 6.56044 13.4986C7.60985 13.4992 8.64397 13.2471 9.57536 12.7636C10.5067 12.2801 11.308 11.5794 11.9114 10.7208C11.9604 10.6469 11.9889 10.5613 11.9942 10.4728C11.9996 10.3842 11.9814 10.2958 11.9417 10.2165C11.902 10.1372 11.8421 10.0698 11.768 10.021C11.694 9.97221 11.6083 9.94381 11.5198 9.93865C10.5209 9.85102 9.55551 9.53487 8.69828 9.01463C7.84105 8.49439 7.11492 7.784 6.57603 6.93837C6.03714 6.09274 5.69992 5.13452 5.59043 4.13777C5.48094 3.14102 5.60212 2.13245 5.9446 1.19C5.97373 1.1146 5.98443 1.03332 5.9758 0.952949C5.96718 0.872575 5.93947 0.795424 5.895 0.727921C5.85053 0.660418 5.79057 0.604517 5.72012 0.564872C5.64968 0.525227 5.57078 0.502986 5.49 0.5Z"
      fill="currentColor"
    />
  </svg>
);

const Command = ({ ...props }: React.ComponentProps<'svg'>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_586_10205)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 5.25C3.5 4.2835 4.2835 3.5 5.25 3.5C6.2165 3.5 7 4.2835 7 5.25V6H9V5.25C9 4.2835 9.7835 3.5 10.75 3.5C11.7165 3.5 12.5 4.2835 12.5 5.25C12.5 6.2165 11.7165 7 10.75 7H10V9H10.75C11.7165 9 12.5 9.7835 12.5 10.75C12.5 11.7165 11.7165 12.5 10.75 12.5C9.7835 12.5 9 11.7165 9 10.75V10H7V10.75C7 11.7165 6.2165 12.5 5.25 12.5C4.2835 12.5 3.5 11.7165 3.5 10.75C3.5 9.7835 4.2835 9 5.25 9H6V7H5.25C4.2835 7 3.5 6.2165 3.5 5.25ZM6 6V5.25C6 4.83579 5.66421 4.5 5.25 4.5C4.83579 4.5 4.5 4.83579 4.5 5.25C4.5 5.66421 4.83579 6 5.25 6H6ZM7 7V9H9V7H7ZM6 10H5.25C4.83579 10 4.5 10.3358 4.5 10.75C4.5 11.1642 4.83579 11.5 5.25 11.5C5.66421 11.5 6 11.1642 6 10.75V10ZM10 10V10.75C10 11.1642 10.3358 11.5 10.75 11.5C11.1642 11.5 11.5 11.1642 11.5 10.75C11.5 10.3358 11.1642 10 10.75 10H10ZM10 6H10.75C11.1642 6 11.5 5.66421 11.5 5.25C11.5 4.83579 11.1642 4.5 10.75 4.5C10.3358 4.5 10 4.83579 10 5.25V6Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_586_10205">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const Option = ({ ...props }: React.ComponentProps<'svg'>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_586_10207)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 5H5.6703L8.47549 11.5454C8.59367 11.8212 8.86483 12 9.16485 12H12.5V11H9.3297L6.52451 4.45456C6.40633 4.1788 6.13517 4 5.83515 4H3.5V5ZM9 5H12.5V4H9V5Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_586_10207">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const Shift = ({ ...props }: React.ComponentProps<'svg'>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_586_10206)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 4.22195L4.1221 8.48902H6.49683V11.9976H9.50317V8.48902H11.8779L8 4.22195ZM7.59217 3.18047C7.81085 2.93985 8.18915 2.93984 8.40783 3.18047L13.3661 8.63632C13.6645 8.96473 13.4316 9.49146 12.9879 9.49146H10.5053V12.2482C10.5053 12.6634 10.1688 13 9.7537 13H6.2463C5.83121 13 5.49472 12.6634 5.49472 12.2482V9.49146H3.01209C2.56841 9.49146 2.33545 8.96474 2.63392 8.63632L7.59217 3.18047Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_586_10206">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const Return = ({ ...props }: React.ComponentProps<'svg'>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_586_10208)">
      <path
        d="M8 4V5H11V9H5.415L7.205 7.205L6.5 6.5L3.5 9.5L6.5 12.5L7.205 11.795L5.415 10H11C11.5523 10 12 9.55228 12 9V5C12 4.44772 11.5523 4 11 4H8Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_586_10208">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const Cursor = ({ ...props }: React.ComponentProps<'svg'>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_588_17636)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.64209 4.26488C3.51994 3.81698 3.93092 3.406 4.37882 3.52815L11.781 5.54694C12.273 5.6811 12.3839 6.32887 11.9647 6.61911L10.3357 7.74688L12.2985 9.70973C12.5426 9.95381 12.5426 10.3495 12.2985 10.5936L10.7076 12.1846C10.4635 12.4287 10.0677 12.4287 9.82367 12.1846L7.86082 10.2218L6.73305 11.8507C6.44281 12.27 5.79504 12.159 5.66087 11.6671L3.64209 4.26488ZM4.79104 4.6771L6.39968 10.5755L7.72066 8.66738L10.2656 11.2123L11.3263 10.1517L8.78132 7.60672L10.6894 6.28574L4.79104 4.6771Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_588_17636">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const NodeEnd = ({ ...props }: React.ComponentProps<'svg'>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2974 8.00413L10.0041 5.70811L7.71082 8.00413L10.0041 10.3002L12.2974 8.00413ZM9.64329 4.65432C9.84252 4.45486 10.1658 4.45488 10.365 4.65432L13.3508 7.64372C13.5497 7.84283 13.5497 8.16542 13.3508 8.36455L10.365 11.354C10.1657 11.5534 9.8425 11.5534 9.64329 11.354L6.79667 8.50395L2.50372 8.50041C2.22758 8.50018 2.00391 8.27614 2.00414 8C2.00436 7.72386 2.22841 7.50018 2.50455 7.50041L6.79704 7.50395L9.64329 4.65432Z"
      fill="currentColor"
    />
  </svg>
);

const NodeStart = ({ ...props }: React.ComponentProps<'svg'>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.70669 8.00413L6 5.70811L8.29331 8.00413L6 10.3002L3.70669 8.00413ZM6.36084 4.65432C6.16161 4.45486 5.83837 4.45488 5.63916 4.65432L2.6533 7.64372C2.45443 7.84283 2.4544 8.16542 2.6533 8.36455L5.63916 11.354C5.83839 11.5534 6.16163 11.5534 6.36084 11.354L9.20747 8.50395L13.5004 8.50041C13.7766 8.50018 14.0002 8.27614 14 8C13.9998 7.72386 13.7757 7.50018 13.4996 7.50041L9.2071 7.50395L6.36084 4.65432Z"
      fill="currentColor"
    />
  </svg>
);

const PanelRightShort = ({ ...props }: React.ComponentProps<'svg'>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_319_5819)">
      <path
        d="M14 2H2C1.73478 2 1.48043 2.10536 1.29289 2.29289C1.10536 2.48043 1 2.73478 1 3V13C1 13.2652 1.10536 13.5196 1.29289 13.7071C1.48043 13.8946 1.73478 14 2 14H14C14.2652 14 14.5196 13.8946 14.7071 13.7071C14.8946 13.5196 15 13.2652 15 13V3C15 2.73478 14.8946 2.48043 14.7071 2.29289C14.5196 2.10536 14.2652 2 14 2ZM2 3H9H14V7H10C9.44771 7 9 7.44772 9 8V13H2V3ZM14 13H10V8H14V13Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_319_5819">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const PanelRightTall = ({ ...props }: React.ComponentProps<'svg'>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_319_5827)">
      <path
        d="M14 2H2C1.73478 2 1.48043 2.10536 1.29289 2.29289C1.10536 2.48043 1 2.73478 1 3V13C1 13.2652 1.10536 13.5196 1.29289 13.7071C1.48043 13.8946 1.73478 14 2 14H14C14.2652 14 14.5196 13.8946 14.7071 13.7071C14.8946 13.5196 15 13.2652 15 13V3C15 2.73478 14.8946 2.48043 14.7071 2.29289C14.5196 2.10536 14.2652 2 14 2ZM2 3H9V13H2V3ZM14 13H10V3H14V13Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_319_5827">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const Warning = ({ ...props }: React.ComponentProps<'svg'>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_656_10614)">
      <g opacity="0.4" filter="url(#filter0_f_656_10614)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.26342 14.6953C7.46642 14.8985 7.73327 15 8.00002 15C8.26672 15 8.53368 14.8984 8.73668 14.6953L14.6953 8.73663C15.1016 8.33048 15.1016 7.66953 14.6953 7.26338L8.73668 1.30473C8.33063 0.898425 7.66948 0.898425 7.26342 1.30473L1.30473 7.26338C0.898425 7.66953 0.898425 8.33048 1.30473 8.73663L7.26342 14.6953ZM7.97047 2.01183C7.97867 2.00378 7.98927 1.99963 8.00002 1.99963C8.01077 1.99963 8.02137 2.00378 8.02957 2.01183L13.9882 7.97048C14.0045 7.98683 14.0045 8.01323 13.9882 8.02958L8.02957 13.9882C8.01322 14.0043 7.98682 14.0043 7.97047 13.9882L2.01183 8.02958C1.99558 8.01323 1.99558 7.98683 2.01183 7.97048L7.97047 2.01183ZM8.5 4.5H7.5V9H8.5V4.5ZM8 10C7.5858 10 7.25 10.3358 7.25 10.75C7.25 11.1642 7.5858 11.5 8 11.5C8.4142 11.5 8.75 11.1642 8.75 10.75C8.75 10.3358 8.4142 10 8 10Z"
          fill="currentColor"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.26342 14.6953C7.46642 14.8985 7.73327 15 8.00002 15C8.26672 15 8.53368 14.8984 8.73668 14.6953L14.6953 8.73663C15.1016 8.33048 15.1016 7.66953 14.6953 7.26338L8.73668 1.30473C8.33063 0.898425 7.66948 0.898425 7.26342 1.30473L1.30473 7.26338C0.898425 7.66953 0.898425 8.33048 1.30473 8.73663L7.26342 14.6953ZM7.97047 2.01183C7.97867 2.00378 7.98927 1.99963 8.00002 1.99963C8.01077 1.99963 8.02137 2.00378 8.02957 2.01183L13.9882 7.97048C14.0045 7.98683 14.0045 8.01323 13.9882 8.02958L8.02957 13.9882C8.01322 14.0043 7.98682 14.0043 7.97047 13.9882L2.01183 8.02958C1.99558 8.01323 1.99558 7.98683 2.01183 7.97048L7.97047 2.01183ZM8.5 4.5H7.5V9H8.5V4.5ZM8 10C7.5858 10 7.25 10.3358 7.25 10.75C7.25 11.1642 7.5858 11.5 8 11.5C8.4142 11.5 8.75 11.1642 8.75 10.75C8.75 10.3358 8.4142 10 8 10Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_656_10614"
        x="-3"
        y="-3"
        width="22"
        height="22"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_656_10614" />
      </filter>
      <clipPath id="clip0_656_10614">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const External = ({ ...props }: React.ComponentProps<'svg'>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_436_3106)">
      <path d="M8 3V4H11.295L3 12.295L3.705 13L12 4.705V8H13V3H8Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_436_3106">
        <rect width="16" height="16" fill="none" />
      </clipPath>
    </defs>
  </svg>
);

const Play = ({ ...props }: React.ComponentProps<'svg'>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_108_12360)">
      <path
        d="M3.5 14C3.36739 14 3.24021 13.9473 3.14645 13.8536C3.05268 13.7598 3 13.6326 3 13.5V2.50001C2.99999 2.41312 3.02263 2.32772 3.06568 2.25225C3.10873 2.17677 3.17071 2.11381 3.2455 2.06959C3.32029 2.02536 3.40532 2.00139 3.4922 2.00003C3.57908 1.99867 3.66481 2.01998 3.74095 2.06186L13.7409 7.56186C13.8194 7.605 13.8848 7.66842 13.9304 7.74549C13.976 7.82256 14 7.91045 14 7.99998C14 8.08952 13.976 8.17741 13.9304 8.25448C13.8848 8.33155 13.8194 8.39496 13.7409 8.43811L3.74095 13.9381C3.66713 13.9787 3.58425 14 3.5 14ZM4 3.34546V12.6544L12.4624 8.00001L4 3.34546Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_108_12360">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const Image = ({ ...props }: React.ComponentProps<'svg'>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.5 6.99997C9.79667 6.99997 10.0867 6.912 10.3334 6.74717C10.58 6.58235 10.7723 6.34808 10.8858 6.074C10.9994 5.79991 11.0291 5.49831 10.9712 5.20733C10.9133 4.91636 10.7704 4.64909 10.5607 4.43931C10.3509 4.22953 10.0836 4.08667 9.79264 4.02879C9.50166 3.97091 9.20006 4.00062 8.92597 4.11415C8.65189 4.22768 8.41762 4.41994 8.2528 4.66661C8.08797 4.91329 8 5.2033 8 5.49997C8 5.8978 8.15804 6.27933 8.43934 6.56063C8.72064 6.84194 9.10218 6.99997 9.5 6.99997ZM9.5 4.99997C9.59889 4.99997 9.69556 5.02929 9.77779 5.08424C9.86001 5.13918 9.9241 5.21727 9.96194 5.30863C9.99978 5.39999 10.0097 5.50052 9.99039 5.59752C9.9711 5.69451 9.92348 5.7836 9.85355 5.85352C9.78363 5.92345 9.69454 5.97107 9.59755 5.99036C9.50056 6.00966 9.40002 5.99975 9.30866 5.96191C9.2173 5.92407 9.13921 5.85998 9.08426 5.77776C9.02932 5.69553 9 5.59886 9 5.49997C9 5.36736 9.05268 5.24019 9.14645 5.14642C9.24021 5.05265 9.36739 4.99997 9.5 4.99997Z"
      fill="currentColor"
    />
    <path
      d="M13 2H3C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V13C2 13.2652 2.10536 13.5196 2.29289 13.7071C2.48043 13.8946 2.73478 14 3 14H13C13.2652 14 13.5196 13.8946 13.7071 13.7071C13.8946 13.5196 14 13.2652 14 13V3C14 2.73478 13.8946 2.48043 13.7071 2.29289C13.5196 2.10536 13.2652 2 13 2ZM13 13H3V10L5.5 7.5L8.295 10.295C8.48236 10.4813 8.73581 10.5858 9 10.5858C9.26419 10.5858 9.51764 10.4813 9.705 10.295L10.5 9.5L13 12V13ZM13 10.585L11.205 8.79C11.0176 8.60375 10.7642 8.49921 10.5 8.49921C10.2358 8.49921 9.98236 8.60375 9.795 8.79L9 9.585L6.205 6.79C6.01764 6.60375 5.76419 6.49921 5.5 6.49921C5.23581 6.49921 4.98236 6.60375 4.795 6.79L3 8.585V3H13V10.585Z"
      fill="currentColor"
    />
  </svg>
);

const CaretRight = ({ ...props }: React.ComponentProps<'svg'>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_502_9069)">
      <path d="M6 4L11 8L6 12V4Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_502_9069">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const Layers = ({ ...props }: React.ComponentProps<'svg'>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_1516_6369)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.76284 8.93999C7.83565 8.97937 7.91711 8.99999 7.99989 8.99999C8.08266 8.99999 8.16413 8.97937 8.23694 8.93999L14.7369 5.43999C14.8164 5.39718 14.8828 5.33365 14.9291 5.25616C14.9753 5.17866 14.9998 5.09008 14.9998 4.99982C14.9998 4.90956 14.9753 4.82098 14.9291 4.74348C14.8828 4.66598 14.8164 4.60246 14.7369 4.55964L8.23694 1.05964C8.16411 1.02034 8.08265 0.999756 7.99989 0.999756C7.91713 0.999756 7.83567 1.02034 7.76284 1.05964L1.26284 4.55964C1.18338 4.60246 1.11698 4.66598 1.07071 4.74348C1.02443 4.82098 1 4.90956 1 4.99982C1 5.09008 1.02443 5.17866 1.07071 5.25616C1.11698 5.33365 1.18338 5.39718 1.26284 5.43999L7.76284 8.93999ZM7.99989 7.93214L2.55459 4.99999L7.99989 2.06789L13.4452 4.99999L7.99989 7.93214ZM7.99988 12C7.91711 12 7.83564 11.9794 7.76283 11.94L1.26283 8.44L1.73693 7.56L7.99988 10.9321L14.2628 7.56L14.7369 8.44035L8.23693 11.9403C8.16409 11.9796 8.08263 12.0001 7.99988 12ZM7.99988 15C7.91711 15 7.83564 14.9794 7.76283 14.94L1.26283 11.44L1.73693 10.56L7.99988 13.9321L14.2628 10.56L14.7369 11.4403L8.23693 14.9403C8.16409 14.9796 8.08263 15.0001 7.99988 15Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1516_6369">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const CaretDown = ({ ...props }: React.ComponentProps<'svg'>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_108_11668)">
      <path d="M12 6L8 11L4 6H12Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_108_11668">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const Plus = ({ ...props }: React.ComponentProps<'svg'>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_1516_8668)">
      <path
        d="M8.5 7.5V2.5L7.5 2.5L7.5 7.5L2.5 7.5L2.5 8.5H7.5L7.5 13.5H8.5L8.5 8.5L13.5 8.5V7.5L8.5 7.5Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1516_8668">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const Launch = ({ ...props }: React.ComponentProps<'svg'>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_227_30060)">
      <path d="M8 3V4H11.295L3 12.295L3.705 13L12 4.705V8H13V3H8Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_227_30060">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const Flow = ({ ...props }: React.ComponentProps<'svg'>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 8L8 2L14 8L8 14L2 8ZM3.41421 8L8 12.5858L12.5858 8L8 3.41421L3.41421 8Z"
      fill="currentColor"
    />
  </svg>
);

const Config = ({ ...props }: React.ComponentProps<'svg'>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_562_3000)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.3535 1.1465L11.7495 4.5425C11.9946 4.7876 12.0674 5.15285 11.9346 5.47315C11.802 5.7932 11.4924 6 11.146 6H8C7.4485 6 7 5.55125 7 5V2H3V14H7V15H3C2.44995 15 2 14.55 2 14V2C2 1.44995 2.44995 1 3 1H8C8.13255 1 8.25975 1.05275 8.3535 1.1465ZM8 2.20705V5H10.7927L8 2.20705ZM13.9494 11H15V12H13.9494C13.8849 12.313 13.7607 12.6106 13.5835 12.8765L14.3285 13.6215L13.6215 14.3285L12.8764 13.5835C12.6105 13.7607 12.313 13.885 12 13.9494V15H11V13.9494C10.687 13.8849 10.3894 13.7607 10.1235 13.5835L9.3785 14.3285L8.6715 13.6215L9.4165 12.8764C9.2393 12.6105 9.115 12.313 9.05055 12H8V11H9.05055C9.11505 10.687 9.2393 10.3894 9.41655 10.1235L8.67155 9.3785L9.37855 8.6715L10.1236 9.4165C10.3895 9.2393 10.687 9.115 11 9.05055V8H12V9.05055C12.313 9.11505 12.6106 9.2393 12.8765 9.41655L13.6215 8.67155L14.3285 9.37855L13.5835 10.1236C13.7607 10.3895 13.885 10.687 13.9494 11ZM10 11.5C10 12.3285 10.6715 13 11.5 13C12.328 12.9991 12.9991 12.328 13 11.5C13 10.6715 12.3285 10 11.5 10C10.6715 10 10 10.6715 10 11.5Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_562_3000">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const Star = ({ ...props }: React.ComponentProps<'svg'>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_1721_27988)">
      <path
        d="M8.00014 3.26L9.38014 6.05L9.61014 6.55L10.1101 6.625L13.1901 7.07L11.0001 9.22L10.6251 9.585L10.7151 10.085L11.2401 13.15L8.48514 11.705L8.00014 11.5L7.53514 11.745L4.78014 13.17L5.28014 10.105L5.37014 9.605L5.00014 9.22L2.79014 7.045L5.87014 6.6L6.37014 6.525L6.60014 6.025L8.00014 3.26ZM8.00014 1L5.72514 5.61L0.640137 6.345L4.32014 9.935L3.45014 15L8.00014 12.61L12.5501 15L11.6801 9.935L15.3601 6.35L10.2751 5.61L8.00014 1Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1721_27988">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const ChartArea = ({ ...props }: React.ComponentProps<'svg'>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_25_15267)">
      <path
        d="M10.2381 4.00755L6.72365 2.10545C6.55539 2.02182 6.36703 1.98709 6.18001 2.00522C5.99299 2.02335 5.81481 2.09361 5.66575 2.208L2 5.0005V1H1V14C1 14.2652 1.10536 14.5196 1.29289 14.7071C1.48043 14.8946 1.73478 15 2 15H15V2.8677L10.2381 4.00755ZM14 10.1045L10.19 11.0056L6.6756 9.5637C6.5062 9.4996 6.32252 9.48295 6.14436 9.51556C5.9662 9.54816 5.80032 9.62877 5.6646 9.7487L2 12.9179V10.6904L6.1875 5.99045L9.6968 8.80045C9.84209 8.90833 10.0137 8.97509 10.1937 8.99374C10.3737 9.01238 10.5554 8.98221 10.7198 8.9064L14 7.30125V10.1045ZM6.26195 2.99245L9.77695 4.89455C9.98453 4.99765 10.2213 5.02591 10.4473 4.97455L14 4.1323V6.1892L10.3094 8.01L6.8 5.2C6.60041 5.04886 6.35118 4.97852 6.10203 5.00301C5.85287 5.02749 5.6221 5.145 5.45575 5.3321L2 9.1919V6.2569L6.26195 2.99245ZM2.27565 14L6.31 10.4944L9.8244 11.9363C10.0077 12.0051 10.2071 12.0185 10.3979 11.9748L14 11.1306V14H2.27565Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_25_15267">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const ChartScatter = ({ ...props }: React.ComponentProps<'svg'>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_25_15268)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 15H15V14H2V1H1V14C1 14.2652 1.10536 14.5196 1.29289 14.7071C1.48043 14.8946 1.73478 15 2 15ZM6 11C6 11.5523 5.55228 12 5 12C4.44772 12 4 11.5523 4 11C4 10.4477 4.44772 10 5 10C5.55228 10 6 10.4477 6 11ZM7 8.5C7.55228 8.5 8 8.05228 8 7.5C8 6.94772 7.55228 6.5 7 6.5C6.44772 6.5 6 6.94772 6 7.5C6 8.05228 6.44772 8.5 7 8.5ZM12 7.5C12 8.05228 11.5523 8.5 11 8.5C10.4477 8.5 10 8.05228 10 7.5C10 6.94772 10.4477 6.5 11 6.5C11.5523 6.5 12 6.94772 12 7.5ZM13 4C13.5523 4 14 3.55228 14 3C14 2.44772 13.5523 2 13 2C12.4477 2 12 2.44772 12 3C12 3.55228 12.4477 4 13 4ZM8 4C8 4.55228 7.55228 5 7 5C6.44772 5 6 4.55228 6 4C6 3.44772 6.44772 3 7 3C7.55228 3 8 3.44772 8 4Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_25_15268">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const ChartLine = ({ ...props }: React.ComponentProps<'svg'>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_25_15269)">
      <path
        d="M2.335 14L5.53 8L9.18 11.245C9.29429 11.3465 9.43034 11.4205 9.5777 11.4613C9.72505 11.502 9.87978 11.5084 10.03 11.48C10.1816 11.4516 10.3246 11.3885 10.4477 11.2957C10.5709 11.2029 10.6709 11.0829 10.74 10.945L13.5 5.45L12.59 5L9.845 10.5L6.195 7.255C6.0836 7.1514 5.95004 7.07459 5.80448 7.0304C5.65891 6.98621 5.50519 6.97581 5.355 7C5.20668 7.02461 5.06582 7.08234 4.94289 7.16891C4.81997 7.25548 4.71815 7.36865 4.645 7.5L2 12.5V1H1V14C1 14.2652 1.10536 14.5196 1.29289 14.7071C1.48043 14.8946 1.73478 15 2 15H15V14H2.335Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_25_15269">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const ChartBar = ({ ...props }: React.ComponentProps<'svg'>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_108_11728)">
      <path
        d="M2 1H1V14C1 14.2652 1.10536 14.5196 1.29289 14.7071C1.48043 14.8946 1.73478 15 2 15H15V14H2V12.5H13V8.5H2V6.5H9V2.5H2V1ZM12 9.5V11.5H2V9.5H12ZM8 3.5V5.5H2V3.5H8Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_108_11728">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const ChartColumn = ({ ...props }: React.ComponentProps<'svg'>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_108_11729)">
      <path
        d="M13.5 14V3H9.5V14H7.5V7H3.5V14H2V1H1V14C1 14.2652 1.10536 14.5196 1.29289 14.7071C1.48043 14.8946 1.73478 15 2 15H15V14H13.5ZM6.5 14H4.5V8H6.5V14ZM12.5 14H10.5V4H12.5V14Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_108_11729">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const Statistic = ({ ...props }: React.ComponentProps<'svg'>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_1033_2257)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 2H11C11.2651 2.0003 11.5193 2.10576 11.7068 2.29323C11.8942 2.4807 11.9997 2.73488 12 3V6C11.9996 6.26511 11.8942 6.51925 11.7067 6.70671C11.5193 6.89417 11.2651 6.99964 11 7H9V6H11V5H9.5V4H11V3H9V2ZM13 5.5L14 4L15 5.5H13ZM3.83335 13.7513L1 11.2242L1.66555 10.4778L4.4861 12.9936L8.3314 9.27119C8.51047 9.09783 8.74972 9.00056 8.99895 8.99978C9.24819 8.99899 9.48804 9.09476 9.6682 9.26699L11.48 10.9991L14.2696 8.01239L15 8.69524L12.2094 11.6831C12.1193 11.7796 12.0109 11.8572 11.8905 11.9115C11.7702 11.9658 11.6403 11.9957 11.5083 11.9994C11.3763 12.0031 11.2449 11.9806 11.1217 11.9332C10.9985 11.8858 10.8859 11.8144 10.7905 11.7231L9.0022 10.0136L5.1692 13.7239C4.99114 13.8963 4.75446 13.9949 4.50667 14C4.25888 14.0051 4.01834 13.9163 3.83335 13.7513ZM8 7H5V5C5.00026 4.73486 5.10571 4.48066 5.29319 4.29319C5.48066 4.10571 5.73486 4.00026 6 4H7V3H5V2H7C7.26512 2.0003 7.5193 2.10576 7.70677 2.29323C7.89424 2.4807 7.9997 2.73488 8 3V4C7.9997 4.26512 7.89424 4.5193 7.70677 4.70677C7.5193 4.89424 7.26512 4.9997 7 5H6V6H8V7ZM3 2V6H4V7H1V6H2V3.5H1V2.5H2V2H3Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1033_2257">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const TableView = ({ ...props }: React.ComponentProps<'svg'>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_642_2168)">
      <path
        d="M13.5 1.5H2.5C2.23478 1.5 1.98043 1.60536 1.79289 1.79289C1.60536 1.98043 1.5 2.23478 1.5 2.5V13.5C1.5 13.7652 1.60536 14.0196 1.79289 14.2071C1.98043 14.3946 2.23478 14.5 2.5 14.5H13.5C13.7652 14.5 14.0196 14.3946 14.2071 14.2071C14.3946 14.0196 14.5 13.7652 14.5 13.5V2.5C14.5 2.23478 14.3946 1.98043 14.2071 1.79289C14.0196 1.60536 13.7652 1.5 13.5 1.5ZM13.5 2.5V4.5H2.5V2.5H13.5ZM8.5 5.5H13.5V9H8.5V5.5ZM7.5 9H2.5V5.5H7.5V9ZM2.5 10H7.5V13.5H2.5V10ZM8.5 13.5V10H13.5V13.5H8.5Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_642_2168">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const TableStream = ({ ...props }: React.ComponentProps<'svg'>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_1032_2223)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 1.5H13C13.8272 1.5 14.5 2.17285 14.5 3V10H8.5V14.5H3C2.17285 14.5 1.5 13.8272 1.5 13V3C1.5 2.17285 2.17285 1.5 3 1.5ZM13 2.5H3C2.72425 2.5 2.5 2.7241 2.5 3V4.5H13.5V3C13.5 2.7241 13.2758 2.5 13 2.5ZM7.5 9V5.5H2.5V9H7.5ZM3 13.5H7.5V10H2.5V13C2.5 13.2759 2.72425 13.5 3 13.5ZM8.5 5.5V9H13.5V5.5H8.5ZM15 13.5L11.5 11V16L15 13.5Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1032_2223">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const Filter = ({ ...props }: React.ComponentProps<'svg'>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_25_15258)">
      <path
        d="M9 14H7C6.73478 14 6.48043 13.8946 6.29289 13.7071C6.10536 13.5196 6 13.2652 6 13V9.205L2.295 5.5C2.10721 5.31332 2.00112 5.05979 2 4.795V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H13C13.2652 2 13.5196 2.10536 13.7071 2.29289C13.8946 2.48043 14 2.73478 14 3V4.795C13.9989 5.05979 13.8928 5.31332 13.705 5.5L10 9.205V13C10 13.2652 9.89464 13.5196 9.70711 13.7071C9.51957 13.8946 9.26522 14 9 14ZM3 3V4.795L7 8.795V13H9V8.795L13 4.795V3H3Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_25_15258">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

// I think this is kinda bad... I think this includes all icons in the js bundle if at least 1 is used...
// not sure tho 🤷
export const customIcon = {
  Library: Library,
  DownToBottom: DownToBottom,
  UpToTop: UpToTop,
  Moon: Moon,
  Command: Command,
  External: External,
  Option: Option,
  Shift: Shift,
  Return: Return,
  Cursor: Cursor,
  NodeEnd: NodeEnd,
  NodeStart: NodeStart,
  Play: Play,
  PanelRightShort: PanelRightShort,
  PanelRightTall: PanelRightTall,
  Warning: Warning,
  Image: Image,
  CaretRight: CaretRight,
  CaretDown: CaretDown,
  Layers: Layers,
  Plus: Plus,
  Launch: Launch,
  Flow: Flow,
  Config: Config,
  Star: Star,
  ChartArea: ChartArea,
  ChartScatter: ChartScatter,
  ChartLine: ChartLine,
  ChartBar: ChartBar,
  ChartColumn: ChartColumn,
  Statistic: Statistic,
  TableView: TableView,
  TableStream: TableStream,
  Filter: Filter,
} as const;

export type CustomIcon = keyof typeof customIcon;
export const customIconNames = Object.keys(customIcon) as CustomIcon[];
